import React from "react";
import { SEO, PageLayout, Background, FoodMenu } from "@bluefin/components";
import { Header, Image, span, Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class ServicesPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"menu-hero"}>
              <Background.Color width={16} color={"white"}>
                <Header as={"h1"}>
                  <Image
                    size={"small"}
                    src={
                      "https://fisherman.gumlet.io/public/5bf72e0e-0e45-45cb-9040-ed3e9a7feb51/stripes_1.png"
                    }
                  />
                  <span>Our Services</span>
                </Header>
              </Background.Color>
            </Background>
          }
        >
          <Grid
            stackable={true}
            className={"menu-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={"16"} textAlign={"left"}>
              <FoodMenu
                header={""}
                showCategoryDropdown={false}
                showMenuLikes={false}
                centerNavigation={true}
                itemDisplayVariant={"HorizontalCard"}
                categoryDisplayVariant={"HorizontalCardRow"}
                menu={{
                  schedules: allFishermanBusinessMenuSchedule.nodes,
                  categories: allFishermanBusinessMenuCategory.nodes,
                  items: allFishermanBusinessMenuItem.nodes,
                }}
                business={{
                  id: fishermanBusiness._id,
                  type: fishermanBusiness.type,
                }}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Services" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
    allFishermanBusinessMenuSchedule {
      nodes {
        availableDays
        categories
        _id
        name
      }
    }
    allFishermanBusinessMenuCategory {
      nodes {
        items
        description
        name
        _id
      }
    }
    allFishermanBusinessMenuItem {
      nodes {
        modifierSets
        description
        _id
        name
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        variations {
          _id
          menuItem
          name
          order
          price
        }
        annotations {
          likeCount
        }
        visible
        available
      }
    }
  }
`;
